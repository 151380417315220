import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "tile"
    }}>{`Tile`}</h2>
    <ComponentCode name="Tile" component="tile" variation="tile" hasReactVersion="tile--default" hasVueVersion="tile--default" hasAngularVersion="?path=/story/tiles--basic" codepen="qwZRWy" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "clickable-tile"
    }}>{`Clickable tile`}</h2>
    <ComponentCode name="Clickable tile" component="tile" variation="tile--clickable" hasReactVersion="tile--clickable" hasVueVersion="tile--clickable" hasAngularVersion="?path=/story/tiles--clickable" codepen="yrOgyM" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "selectable-tile"
    }}>{`Selectable tile`}</h2>
    <ComponentCode name="Selectable tile" component="tile" variation="tile--selectable" hasReactVersion="tile--selectable" hasVueVersion="tile--selectable" codepen="wZGgKK" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "expandable-tile"
    }}>{`Expandable tile`}</h2>
    <ComponentCode name="Expandable tile" component="tile" variation="tile--expandable" hasReactVersion="tile--expandable" hasVueVersion="tile--expandable" codepen="QPNdNG" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "grid-tile"
    }}>{`Grid tile`}</h2>
    <ComponentCode name="Grid tile" component="tile" variation="tile--grid" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ComponentDocs component="tile" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      